<div class="row">
  <div class="col-md-12 col-12">
    <ul class="cat-list">
      <li (click)="navigateToSub(cat.name,cat.fsc_id)"
      class="sub-item"
      *ngFor="let cat of subCat">
        {{cat.name}}
        <em class="fa fa-chevron-right float-right"></em>
      </li>
    </ul>
  </div>
</div>

