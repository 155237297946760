<div class="menu">
  <div class="row">
    <div class="col-md-12 col-12 text-center">
      <img class="icon float-left"
      src="../../../../assets/images/Group 1logo.svg"
      alt="Filament logo">
      <span class="header">Drinks</span>
      <img (click)="navigateHome()"
      class="icon float-right"
      src="../../../../assets/images/Group 63home.svg"
      alt="Filament logo">
      <img *ngIf="isSelected('/menu/food/:sub/:subId')"
      (click)="onBackClicked()"
      class="icon float-right"
      src="../../../../assets/images/Group 15back.svg"
      alt="Filament logo">
    </div>
  </div>
  <!-- <router-outlet></router-outlet> -->
</div>

<div class="row sub-list" style="overflow: scroll;">

	  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/front.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/001.jpg"
      alt="Filament Menu">
  
  </div>

  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/002.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/003.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/004.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/005.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/drink/006.jpg"
      alt="Filament Menu">
  
  </div>

</div>

<!--
<div class="row sub-list">
  <div class="col-md-12 col-12">
    <ul class="cat-list">
      <li
      class="sub-item"
      *ngFor="let cat of drinkCategories"
      (click)="navigateToSub(cat.name, cat.dc_id)">
        {{cat.name}}
        <em class="fa fa-chevron-right float-right"></em>
      </li>
    </ul>
  </div>
</div>
-->

<div class="container foot">
  <div class="footer">
    <span><b>Our Standard Measure is 30ml
      • We levy 10% Service Charge
      • Government Taxes as applicable</b></span>
  </div>
</div>
