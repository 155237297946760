<div class="menu">
  <div class="row text-center p-bo">
    <div class="col-md-12 col-12">
      <img src="../../../assets/images/Group 1logo png.png" alt="Filament logo">
    </div>
  </div>

  <div class="row text-center m-bo">
    <div class="col-md-12 col-12">
      <h3>Digital Menu</h3>
    </div>
  </div>

  <div class="row text-center"
  style="margin-top: 80px;">
    <div class="col-md-12 col-12">
      <h5>Please Select</h5>
    </div>
  </div>

  <div class="row p-bo">
    <div class="col-md-1 col-1"></div>
    <div class="col-md-5 col-5 text-center">
      <!-- <img (click)="navigateToDrinks()"
      width="90px" height="90px"
      src="../../../assets/images/Group 122drinks white.svg"
      alt="Drinks"> -->
      <button class="btn btn-cust btn-lg"
      (click)="navigateToDrinks()">
        <em>
          <img src="../../../assets/images/drinks.svg" alt="Drinks">
        </em>
        Drinks
      </button>
    </div>
    <div class="col-md-5 col-5 text-center">
      <!-- <img (click)="navigateToFood()"
      width="90px" height="90px"
      src="../../../assets/images/Group 156food wh.svg"
      alt="Food"> -->
      <button class="btn btn-cust btn-lg"
      (click)="navigateToFood()">
        <em>
          <img src="../../../assets/images/food.svg" alt="Food">
        </em>
        Food
      </button>
    </div>
    <div class="col-md-1 col-1"></div>
  </div>

  <div class="row text-center m-bo">
    <div class="col-md-12 col-12">
      <p style="font-size: larger;">
        Give Us Your
        <a
        routerLink="/feedback" style="text-decoration: underline; color: #d4af37;">Feedback</a>
      </p>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
