<div class="detail">
  <div class="row p-bo">
    <div class="col-md-12 col-12 text-center">
      <em>
        <img src="../../../../../assets/images/Group 30zigzag.svg" alt="zig">
      </em>&nbsp;
      <span class="title">{{subName}}</span>&nbsp;
      <em>
        <img src="../../../../../assets/images/Group 30zigzag.svg" alt="zig">
      </em>
    </div>
  </div>
  <div class="row" *ngIf="subId != '5' && subId != '10' && subId != '11'">
    <div class="col-md-12 col-12">
      <table width="100%" style="margin-left: 1rem;">
        <tbody>
          <tr *ngFor="let item of foodItems">
            <td width="5%" *ngIf="item.description"
            class="float-left" (click)="item.isCollapsed = !item.isCollapsed">
              <i *ngIf="item.isCollapsed" class="fa fa-chevron-down">&nbsp;</i>
              <i *ngIf="!item.isCollapsed" class="fa fa-chevron-up">&nbsp;</i>
            </td>
            <td width="65%" *ngIf="item.description" class="m-b">
              <span (click)="item.isCollapsed = !item.isCollapsed"
                class="food_title"
                aria-controls="item.fd_id"
                [attr.aria-expanded]="!collapsed"
                [style.font-weight]="item.isCollapsed ? '100' : 'bold'">
                <!-- <i *ngIf="item.isCollapsed" class="fa fa-chevron-down">&nbsp;</i>
                <i *ngIf="!item.isCollapsed" class="fa fa-chevron-up">&nbsp;</i> -->
                {{item.name}}
              </span><br>
              <span id="item.fd_id"
              [ngbCollapse]="item.isCollapsed">
                {{item.description}}
              </span>
            </td>
            <td width="70%" *ngIf="!item.description">
              <span class="food_title">{{item.name}}</span>
            </td>
            <td class="price text-center" width="30%"
            *ngFor = "let p of item.price">
              <!-- <span *ngFor = "let p of item.price" >{{p.price}}</span> -->
              {{p.price}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" *ngIf="subId === '5' || subId === '11'">
    <div class="col-md-12 col-12">
      <ul class="cat-list">
        <li class="sub-item"
          *ngFor="let item of foodItems">
          <span class="food_title">{{item.name}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="subId === '5' || subId === '10' || subId === '11'">
    <div class="col-md-12 col-12">
      <!-- <ul class="cat-list">
        <li class="sub-item"
          *ngFor="let p of foodItems">
          {{item.name}}
        </li>
      </ul> -->
      <table width="100%">
        <tbody>
          <tr *ngFor="let p of foodItems[0].price[0].price">
            <td class="price text-center" width="50%">{{p.item}}</td>
            <td class="price text-center" width="50%">{{p.price}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
