import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUrl } from 'src/commons/common';

@Component({
  selector: 'app-tabakh',
  templateUrl: './tabakh.component.html',
  styleUrls: ['./tabakh.component.scss']
})
export class WakadTabakhComponent implements OnInit {
  drinkCategories;
  constructor(private router: Router,private location: Location, private http: HttpClient) { }

  ngOnInit(): void {
    this.drinksCategories();
  }

  navigateHome(){
    this.router.navigate(['/', 'wakad-menu']);
  }

  onBackClicked() {
    this.location.back();
  }

  isSelected(route: string): boolean {
    return route === this.router.url;
  }

  drinksCategories(){
    this.http.get(CommonUrl.URL + "drink_categories")
    .subscribe(response => {
      // console.log("res", response);
      this.drinkCategories = response;
    }, error => console.log("err", error));
  }

  navigateToSub(dcName, dcId){
    this.router.navigate(['/wakad-menu/tabakh/', dcName , dcId]);
  }
}
