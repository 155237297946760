import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-street-specials',
  templateUrl: './street-specials.component.html',
  styleUrls: ['./street-specials.component.scss']
})
export class StreetSpecialsComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.getSingle();
  }

  getSingle(){
    this.router.navigate(['/', 'menu', 'food', 'main-course','pan-asian', 'Pao-Wow', 12]);
  }

}
