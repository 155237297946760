<div class="menu">
  <div class="row text-center p-bo">
    <div class="col-md-12 col-12">
      <img src="../../../assets/images/Group 1logo png.png" alt="Filament logo">
    </div>
  </div>

  <div class="row m-bo">
    <div class="col-md-12 col-12">
      <div class="container">
        <form [formGroup] = "guestForm">
          <div class="form-group">
            <!-- <input type="phone" class="form-control"
            placeholder="Mobile No.*"> -->
            <select
            class="form-control"
            name="guests"
            formControlName="guests"
            [(ngModel)]="no_of_guests"
            (change)="changes()"
            required>
              <option value="" selected disabled>No. of Guests*</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <div class="alert alert-danger"
            *ngIf="guestForm.get('guests').touched && guestForm.get('guests').invalid">
              Please Select No. of Guests
            </div>
          </div>
          <div *ngFor="let person of persons; let i = index">
            <div class="form-group">

              <input type="text" class="form-control"
              name="name" formControlName="name" [(ngModel)]="person.name"
              placeholder="Name*" required>
              <div
              class="alert alert-danger"
              *ngIf="guestForm.get('name').touched && guestForm.get('name').invalid">
                Please enter valid Name
              </div>
            </div>
            <div class="form-group">
              <input type="phone" class="form-control"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              name="phone" formControlName="phone"
              [(ngModel)]="person.contact_number"
              placeholder="Mobile No.*" required>
              <div class="alert alert-danger"
              *ngIf="guestForm.get('phone').touched && guestForm.get('phone').invalid">
                Please enter valid Phone Number
              </div>
            </div>
          </div>
          <div class="form-group">
            <input type="checkbox" id="doContact"
            name="doContact" formControlName="doContact"
            (change)="doContact = !doContact" [checked]="doContact">&nbsp;
            <label for="doContact">Contact Me For Exciting Offers & Events</label>
          </div>
          <button
          class="btn btn-block btn-cust"
          (click)="submit()"
          [disabled]="!guestForm.valid">Submit</button>
        </form>
      </div>
    </div>
  </div>

  <div class="row text-center m-t">
    <div class="col-md-12 col-12">
      <div class="container">
        <span>We are collecting this information as per government’s directive.
          These details will be private with us.
          We will not share your data with any third party company/vendor.
          However, if required, we will provide your details to authorised government
          officials for contact tracing only.</span>
      </div>
    </div>
  </div>
</div>

<div class="modal" [style.display]="showSuccess? 'block': 'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Thank You!!!
      </div>
      <div class="modal-body text-center">
        <em><img width="75px" height="75px"
          src="../../..//assets/images/logo_black.png" alt="Logo"></em>
        <h2>Welcome to Filament!</h2>
      </div>
    </div>
  </div>
</div>
