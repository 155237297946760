import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FoodService } from 'src/app/components/menu/food/food.service';
import { CommonUrl } from 'src/commons/common';

@Component({
  selector: 'app-indian',
  templateUrl: './indian.component.html',
  styleUrls: ['./indian.component.scss']
})
export class IndianComponent implements OnInit {

  subCat;
  sub: Subscription;

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getSubCategories(3);
  }

  navigateToSub(subName, subId){
    this.router.navigate(['/', 'menu', 'food', 'main-course', 'indian', subName, subId]);
  }

  getSubCategories(catId){
    const url = CommonUrl.URL + 'food_sub_categories/' + catId;
    this.http.get(url).subscribe((response) => {
      this.subCat = response;
    }, err => console.log(err));
  }
}
