import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './wakad-menu.component.html',
  styleUrls: ['./wakad-menu.component.scss']
})
export class WakadMenuComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToDrinks(){
    this.router.navigate(['/', 'wakad-menu', 'drinks']);
  }
  
   navigateToTabakh(){
    this.router.navigate(['/', 'wakad-menu', 'tabakh']);
  }

  navigateToFood(){
    this.router.navigate(['/', 'wakad-menu', 'food']);
  }

}
