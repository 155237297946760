import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FoodService } from '../food.service';
import { HttpClient } from '@angular/common/http';
import { CommonUrl } from 'src/commons/common';

@Component({
  selector: 'app-subcategory-details',
  templateUrl: './subcategory-details.component.html',
  styleUrls: ['./subcategory-details.component.scss']
})
export class SubcategoryDetailsComponent implements OnInit {
  public collapsed = true;
  subId: string;
  subName: string;
  foodItems;
  sub: Subscription;

  constructor(
    private foodService: FoodService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(paramMap => {
      this.subId = paramMap['subId'];
      this.subName = paramMap['sub'];
      this.getFoodItem(this.subId);
    });
  }

  getFoodItem(subId){
    const url = CommonUrl.URL + 'food_details/' + subId;
    this.http.get(url).subscribe((response) => {
      this.foodItems = response;
      for (let i = 0; i < this.foodItems.length; i++) {
        this.foodItems[i].price = JSON.parse(this.foodItems[i].price);
        this.foodItems[i].isCollapsed = true;
      }
      // console.log('f', JSON.parse(this.foodItems[0].price));
    }, err => console.log(err));
  }

  // ngOnDestroy(): void{
  //   this.sub.unsubscribe();
  // }

}
