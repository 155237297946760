<div class="row menu">
  <div class="col">

    <ul ngbNav [activeId]="route.fragment | async" class="nav-tabs justify-content-center">
      <li [ngbNavItem]="'twenty'" >
        <a ngbNavLink
          [routerLink]="['/menu/food/main-course/mid-course']"
          [routerLinkActive]="['active']"
          #rla_y="routerLinkActive"
          [fragment]="'two'">
          <img *ngIf="rla_y.isActive" [src]="'../../../../../assets/images/Group 61mid course.svg'" alt="Starters">
          Italian
        </a>
      </li>
      <li [ngbNavItem]="'thirty'">
        <a ngbNavLink
          [routerLink]= "['/', 'menu', 'food','main-course','pan-asian', 'Pan-Asian', 11]"
          [routerLinkActive]="['active']"
          #rfa_x="routerLinkActive"
          [fragment]="'two'">
          <img *ngIf="rfa_x.isActive" src="../../../../../assets/images/Pan-AsianPan-Asian.svg" alt="Main course">
          Pan Asian
        </a>
      </li>
      <li [ngbNavItem]="'forty'" >
        <a ngbNavLink
          [routerLink]= "['/menu/food/main-course/indian']"
          [routerLinkActive]="['active']"
          #rfa_z="routerLinkActive"
          [fragment]="'two'">
            <img *ngIf="rfa_z.isActive" src="../../../../../assets/images/IndianIndian.svg" alt="Main course">
            Indian
        </a>
      </li>
    </ul>
  </div>
</div>

<router-outlet></router-outlet>
