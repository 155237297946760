<div class="menu"  style="overflow: scroll;">
  <div class="row text-center p-bo">
    <div class="col-md-12 col-12">
      <img src="../../../assets/images/wakad-logo.png" alt="Filament Wakad logo">
    </div>
  </div>

  <div class="row text-center m-bo">
    <div class="col-md-12 col-12">
      <h3>Digital Menu</h3>
    </div>
  </div>

  <div class="row text-center"
  style="margin-top: 80px;">
    <div class="col-md-12 col-12">
      <h5>Please Select</h5>
    </div>
  </div>
  
 
  

  <div class="row p-bo">
    <div class="col-md-3 col-3"></div>
    <div class="col-md-3 col-3 text-center">
      <!-- <img (click)="navigateToDrinks()"
      width="90px" height="90px"
      src="../../../assets/images/Group 122drinks white.svg"
      alt="Drinks"> -->
      <button class="btn btn-cust btn-lg"
      (click)="navigateToDrinks()">
        <!--  <em>
          <img src="../../../assets/images/drinks.png" alt="Drinks">
        </em> -->
        Drinks
      </button>
    </div>
	
	
	
	
    <div class="col-md-3 col-3 text-center">
      <!-- <img (click)="navigateToFood()"
      width="90px" height="90px"
      src="../../../assets/images/Group 156food wh.svg"
      alt="Food"> -->
      <button class="btn btn-cust btn-lg"
      (click)="navigateToFood()">
        <!--  <em>
          <img src="../../../assets/images/food.png" alt="Food">
        </em> -->
        &nbsp;Food&nbsp;
      </button>
    </div>
    <div class="col-md-3 col-3"></div>
  </div>
  
   <div class="row p-bo">
   <div class="col-md-4 col-4"></div>
  
	 <div class="col-md-4 col-4 text-center">
      <!-- <img (click)="navigateToDrinks()"
      width="90px" height="90px"
      src="../../../assets/images/Group 122drinks white.svg"
      alt="Drinks"> -->
      <button class="btn btn-cust btn-lg"
      (click)="navigateToTabakh()">
       <!-- <em>
          <img src="../../../assets/images/tabakh.png" alt="Tabakh">
        </em> -->
        Tabakh
      </button>
    </div>
  <div class="col-md-4 col-4"></div>
  </div>

   
</div>

<router-outlet></router-outlet>
