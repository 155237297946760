import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'filament';

  ngOnInit(){
    // this.matrix();
  }

  // [
  //   0 0 0 0 0
  //   0 0 0 0 0
  //   0 0 0 0 0
  //   0 0 0 0 0
  //  ]
  array = [];
  matrix(){
    let x = 4;
    let y = 4;

    for(let i = 0; i < y; i++){
      // let arr = [1, 0, 0, 0];
      for(let j = 0; j < x; j++){
        // var ar = arr.shift();
        // this.array.push(ar);
        // let arr = x[i] * y[j];
        let arr;
        console.log("x", i, j)
        if(i == j || j == 3){
          arr = 1

        }
        else{
          arr = 0
        }
        this.array.push(arr);

      }
      // console.log(" this is arr", arr);


      console.log("0", this.array);
    }
  }
}
