import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUrl } from 'src/commons/common';

@Component({
  selector: 'app-drinks-subcategory',
  templateUrl: './drinks-subcategory.component.html',
  styleUrls: ['./drinks-subcategory.component.scss']
})
export class DrinksSubcategoryComponent implements OnInit {
  public collapsed = true;
  fc_id;
  subName;
  arr;
  constructor(private router: Router,private location: Location, private http: HttpClient, private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.fc_id = this.route.snapshot.paramMap.get('dc_id');
    this.subName = this.route.snapshot.paramMap.get('sub');
    // console.log("fc", this.fc_id);
    this.getDrinksInfo();
  }

  navigateHome(){
    this.router.navigate(['/', 'menu']);
  }

  onBackClicked() {
    this.location.back();
  }

  isSelected(route: string): boolean {
    return route === this.router.url;
  }

  getDrinksInfo(){
    this.http.get(CommonUrl.URL + 'drink_sub_categories/' + this.fc_id)
    .subscribe(drinksSub => {


      this.arr = drinksSub;

      if (this.arr.length == 0){
        //  console.log("call here ?");
        this.http.get(CommonUrl.URL + 'drink_information_dc_id/' + this.fc_id)
        .subscribe(response => {
          // console.log("res", response);
          this.arr = response;
          for(let i = 0; i<this.arr.length; i++){
            this.arr[i].price = JSON.parse(this.arr[i].price);
          }
        });
      }

      // console.log("drinksSUb", this.arr);
      this.arr.forEach(element => {
          element.isCollapsed = true;
          // console.log("er", element);
          this.http.get(CommonUrl.URL + 'drink_information/' + element.dsc_id)
          .subscribe(information => {
            // console.log("drinks ino",typeof information);
            element["children"] = information;
            // element["children"].isCollapsed = false;
            for(let i = 0; i<element['children'].length; i++){
              element['children'][i].price = JSON.parse(element['children'][i].price);
            }
          }, error => console.log("drinks info error", error));
      });
      // let copyDrink = [...drinksSub];
      // drinksSub.forEach()
    }, error => console.log("error", error));
  }

}
