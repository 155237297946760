<div class="menu">
  <div class="row">
    <div class="col-md-12 col-12 text-center">
      <img class="icon float-left"
      src="../../../../assets/images/Group 1logo.svg"
      alt="Filament logo">
      <span class="header">Tabakh</span>
      <img (click)="navigateHome()"
      class="icon float-right"
      src="../../../../assets/images/Group 63home.svg"
      alt="Filament logo">
      <img *ngIf="isSelected('/menu/food/:sub/:subId')"
      (click)="onBackClicked()"
      class="icon float-right"
      src="../../../../assets/images/Group 15back.svg"
      alt="Filament logo">
    </div>
  </div>
  <!-- <router-outlet></router-outlet> -->
</div>

<div class="row sub-list" style="overflow: scroll;">
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/tabakh-front.jpg"
      alt="Filament Menu">
   
  </div>
  
  <div class="col-md-12 col-12">
  <img  style=" width: 100%;height: auto;"
      src="../../../../assets/images/tabakh-01.jpg"
      alt="Filament Menu">
  
  </div>
  
  
  <div class="col-md-12 col-12">
  <img  style=" width: 100%;height: auto;"
      src="../../../../assets/images/tabakh-02.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img  style=" width: 100%;height: auto;"
      src="../../../../assets/images/tabakh-03.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img  style=" width: 100%;height: auto;"
      src="../../../../assets/images/tabakh-04.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img  style=" width: 100%;height: auto;"
      src="../../../../assets/images/tabakh-back.jpg"
      alt="Filament Menu">
  
  </div>
  
</div>
<div class="container foot">
  <div class="footer">
    
  </div>
</div>
