import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sunday-brunch',
  templateUrl: './sunday-brunch.component.html',
  styleUrls: ['./sunday-brunch.component.scss']
})
export class SundayBrunchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
