import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss']
})
export class WakadFoodComponent implements OnInit {

  // links = [
  //   {title: 'Starters', fragment: ''},
  //   {title: 'Mid Course', fragment: 'menu/drinks'},
  // ]
  // categories = [
  //   {
  //     name: 'Bar Bites'
  //   },
  //   {
  //     name: 'Quicky for Herbivorous'
  //   },
  //   {
  //     name: 'Quicky for Carnivorous'
  //   },
  //   {
  //     name: 'Let\'s Nacho'
  //   },
  //   {
  //     name: 'Fila Wings'
  //   },
  //   {
  //     name: 'Oven Clay Yummies'
  //   }
  // ];

  constructor(
    private router: Router,
    private location: Location,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  navigateHome(){
    this.router.navigate(['/', 'wakad-menu']);
  }

  onBackClicked() {
    this.location.back();
  }

  isSelected(route: string): boolean {
    return route === this.router.url;
  }
}
