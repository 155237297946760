import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUrl } from 'src/commons/common';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  showSuccess = false;
  doContact = true;
  persons = [
    {name: '', contact_number: ''}
  ];
  no_of_guests;
  guestForm = new FormGroup({
    name: new FormControl(null, Validators.required ),
    phone: new FormControl(null, Validators.required),
    guests: new FormControl('', Validators.required)
  });

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }
  submit(){
    // console.log("here",this.persons, this.no_of_guests);
    let body = {
      information: this.persons,
      no_of_guest: this.no_of_guests,
      do_contact: this.doContact
    };
    this.http.post(CommonUrl.URL + 'guest', body)
    .subscribe(response => {
      console.log("response", response);
      this.guestForm.reset();
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
       }, 3000);
    });

  }

  changes(){
    let p =   {name: '', contact_number: ''};

    // console.log("called?", this.no_of_guests, this.persons.length);
    if(this.no_of_guests > this.persons.length){
      if (this.no_of_guests > 1){
        var you =   [...this.persons]
        // console.log("you", you.length, this.no_of_guests);
        let sub =  (+this.no_of_guests) - you.length;
        // console.log("sub", sub);
        for (let i = 0; i < sub; i++){
          this.persons.push(p);
        }
        // this.persons = you;
      }
    }
    if(this.no_of_guests < this.persons.length){
      // console.log(" to be popped");
      let us= [...this.persons];
      let sub =  us.length - (+this.no_of_guests);
      // console.log("sub", sub,"copy", us)
      for (let i = 0; i < sub; i++){
        this.persons.pop();
      }
      // this.persons.slice(1,2)
      // console.log("us", us)
      // this.persons = us;

    }

  }
}
