<div class="menu">
  <div class="row">
    <div class="col-md-12 col-12 text-center">
      <img class="icon float-left"
      src="../../../../assets/images/Group 1logo.svg"
      alt="Filament logo">
      <span class="header">Food</span>
      <img (click)="navigateHome()"
      class="icon float-right"
      src="../../../../assets/images/Group 63home.svg"
      alt="Filament logo">
      <img
      (click)="onBackClicked()"
      class="icon float-right"
      src="../../../../assets/images/Group 15back.svg"
      alt="Filament logo">
    </div>
  </div>

  
<div class="row sub-list" style="overflow: scroll;">

	
	  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/front.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/001.jpg"
      alt="Filament Menu">
  
  </div>

  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/002.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/003.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/004.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/005.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/006.jpg"
      alt="Filament Menu">
  
  </div>

<div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/menu/food/007.jpg"
      alt="Filament Menu">
  
  </div>

</div>
  <!--
  <div class="row">
    <div class="col-md-12 col-12 cols">
      <ul ngbNav [activeId]="route.fragment | async" class="nav-tabs justify-content-center">
        <li
          [ngbNavItem]="'One'"
          [routerLinkActive]="['active']"
          #rla_z="routerLinkActive"
         >
          <a ngbNavLink  [routerLink]= "'/menu/food/starters'" [fragment]="'One'" >
            <img *ngIf="rla_z.isActive" [src]="'../../../../assets/images/Vectorstarter.svg'" alt="Starters">
            Starters
          </a>
        </li>
        <li
          [ngbNavItem]="'poppy'"
          [routerLinkActive]="['active']"
          #rla_p="routerLinkActive"
         >
          <a ngbNavLink  [routerLink]= "['/', 'menu', 'food','pan-asian', 'Pao-Wow', 12]" [fragment]="'poppy'" >
            <img *ngIf="rla_p.isActive" [src]="'../../../../assets/images/Pao-WowPao-Wow.svg'" alt="Starters">
            Street Specials
          </a>
        </li>
        <li [ngbNavItem]="'two'" [routerLinkActive]="['active']" #rfa_a="routerLinkActive" >
          <a
          ngbNavLink
          [routerLink]="'/menu/food/main-course'"
          [fragment]="'two'">
            <img *ngIf="rfa_a.isActive" src="../../../../assets/images/Group 65main course.svg" alt="Main course">
            Main Course
          </a>
        </li>
      </ul>



    </div>
  </div> -->
</div>
<div class="sub-list">
  <router-outlet></router-outlet>
</div>

<div class="container foot">
  <div class="footer">
    <p><b>We levy 10% Service Charge • Government Taxes as applicable</b></p>
  </div>
</div>

