import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { WakadMenuComponent } from './components/wakad-menu/wakad-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FoodComponent } from './components/menu/food/food.component';
import { DrinksComponent } from './components/menu/drinks/drinks.component';
import { WakadDrinksComponent } from './components/wakad-menu/drinks/drinks.component';
import { WakadTabakhComponent } from './components/wakad-menu/tabakh/tabakh.component';
import { WakadFoodComponent } from './components/wakad-menu/food/food.component';
import { HttpClientModule } from '@angular/common/http';
import { DrinksSubcategoryComponent } from './components/menu/drinks/drinks-subcategory/drinks-subcategory.component';
import { WakadDrinksSubcategoryComponent } from './components/wakad-menu/drinks/drinks-subcategory/drinks-subcategory.component';
import { PanAsianComponent } from './components/app/food/pan-asian/pan-asian.component';
import { IndianComponent } from './components/app/food/indian/indian.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubcategoryDetailsComponent } from './components/menu/food/subcategory-details/subcategory-details.component';
import { StartersComponent } from './components/menu/food/starters/starters.component';
import { MidCourseComponent } from './components/menu/food/mid-course/mid-course.component';
import { MainCourseComponent } from './components/menu/food/main-course/main-course.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { EntryComponent } from './components/entry/entry.component';
import { StreetSpecialsComponent } from './components/app/food/street-specials/street-specials.component';
import { SundayBrunchComponent } from './sunday-brunch/sunday-brunch.component';
import { UrboMenuComponent } from './components/urbo-menu/urbo-menu.component';

@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    MenuComponent,
	WakadMenuComponent,
    FoodComponent,
    DrinksComponent,
	WakadDrinksComponent,
	WakadTabakhComponent,
	WakadFoodComponent,
    DrinksSubcategoryComponent,
	WakadDrinksSubcategoryComponent,
    StartersComponent,
    MidCourseComponent,
    MainCourseComponent,
    SubcategoryDetailsComponent,
    PanAsianComponent,
    IndianComponent,
    FeedbackComponent,
    EntryComponent,
    StreetSpecialsComponent,
    SundayBrunchComponent,
    UrboMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
