import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { report } from 'process';
import { BehaviorSubject } from 'rxjs';
import { CommonUrl } from 'src/commons/common';

@Injectable({
  providedIn: 'root'
})
export class FoodService {
  private subCategories: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private foodItem: BehaviorSubject<any[]> = new BehaviorSubject([]);

  // getSub(catId){
  //   this.getSubCategories(catId);
  //   return this.subCategories.asObservable();
  // }

  // getFood(subId){
  //   this.getFoodItem(subId);
  //   return this.foodItem.asObservable();
  // }

  constructor(private http: HttpClient) { }

  // getSubCategories(catId){
  //   const url = CommonUrl.URL + 'food_sub_categories/' + catId;
  //   this.http.get(url).subscribe((response: Object[]) => {
  //     // console.log(response);
  //     this.subCategories.next(response);
  //   }, err => console.log(err));
  // }

  // getFoodItem(subId){
  //   const url = 'http://localhost:3000/food_details/' + subId;
  //   this.http.get(url).subscribe((response: Object[]) => {
  //     this.foodItem.next(response);
  //     console.log("food item", response[0]['price'])
  //   }, err => console.log(err));
  // }
}
