<div class="menu">
  <div class="row">
    <div class="col-md-12 col-12 text-center">
      <img class="icon float-left"
      src="../../../../assets/images/Group 1logo.svg"
      alt="Filament logo">
      <span class="header">Food</span>
      <img (click)="navigateHome()"
      class="icon float-right"
      src="../../../../assets/images/Group 63home.svg"
      alt="Filament logo">
      <img
      (click)="onBackClicked()"
      class="icon float-right"
      src="../../../../assets/images/Group 15back.svg"
      alt="Filament logo">
    </div>
  </div>

   
</div>

<div class="row sub-list" style="overflow: scroll;">

  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_front.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_1.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_2.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_3.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_4.jpg"
      alt="Filament Menu">
  
  </div>
  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_5.jpg"
      alt="Filament Menu">
  
  </div>

  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_6.jpg"
      alt="Filament Menu">
  
  </div>

  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_7.jpg"
      alt="Filament Menu">
  
  </div>
  
    <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_8.jpg"
      alt="Filament Menu">
  
  </div>


  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_9.jpg"
      alt="Filament Menu">
  
  </div>


  
  <div class="col-md-12 col-12">
  <img style=" width: 100%;height: auto;"
      src="../../../../assets/images/food_back.jpg"
      alt="Filament Menu">
  
  </div>
 
</div> 

<div class="container foot">
  <div class="footer">
    
  </div>
</div>

