
  <!-- section header start -->
    <div class="header_section">
    	<div class="container-fluid">
    		<div class="row">
    			<div class="col-md-6">
    				
    			</div>
    			<div class="col-md-6">
    				
    			</div>
    		</div>
    	</div>
    	<div class="container">
    		<div class="banner_section layout_padding">
    			<div class="row">
    				<div class="col-md-6">
    					 
    				</div>
    				<div class="col-md-6">
    				 
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
    <!-- section header end -->
    <!-- section Professional start -->
    <div class="Professional_section layout_padding" style="background:#fff;padding-bottom:90px">
    	<div class="container"  style="background:#fff">
    		<h2 class="professional_text" style="font-weight:700">WHO WE ARE</h2>
            
            <p class="long_text" style="color:#000;font-family:Roboto">We curate experiences to create meaningful connections. Filament bar is the ultimate host for you to connect over a memorable, shared experience.</p>
			
            
			<p class="long_text" style="color:#000;font-family:Roboto">It is designed to be a social bar with an open kitchen merging indoor and outdoor
spaces. Filament bar strips away the right formalities of dining while focusing on
the quality of food, warm service, and relaxed casual ambience.</p>


<p class="long_text" style="color:#000;font-family:Roboto">Open for Brunch, lunch and dinner, with in bar dining.<br/>

Filament bar endeavours to offer a holistic dining experience and thus showcases
a range of cocktails in its signature beverage section, which have been given the
same tender, love and care as the cuisine, applying extensive use of molecular
mixology with absolute cutting edge innovation never been seen before.The
dishes on offer showcase some classics and all-time favourites, retaining their
original essence, enhanced using elements of modern cooking techniques like
elements of molecular gastronomy and presentation to add an element of
surprise to the dining experience.</p>
			
			
			
			
    	</div>
    </div>
    <!-- section Professional end -->
    <!-- section about start -->
    <div class="about_section" style="margin-top:0px" >
    <div class="container-fluid">
    	<div class="row">
    		<div class="col-md-6 ">
    			<div class="right_text">
    				<h2 class="who_text"><span style="color: #54e4ba;">Filament Bar Address</span> </h2>
					
    				<p class="ipsum_text"><span style="color: #54e4ba;;font-size:21px"><strong>(BANER)</strong></span><br/>
					Rajwada chowk, Baner, Main,<br/>
					Baner Road, Pune, Maharashtra 411045<br/>
					Phone: <a href="tel:08828838825" style="color:#fff"><strong>08828838825</strong></a>
					</p>
    				<p>&nbsp;</p>
					
					<p class="ipsum_text"><span style="color: #54e4ba;font-size:21px"><strong>(WAKAD)</strong></span><br/>
					247, Wakad Rd, Kaspate Wasti, Wakad,<br/>
Pimpri-Chinchwad, Maharashtra 411057<br/>
					Phone: <a href="tel:8828838827" style="color:#fff"><strong>8828838827</strong></a>
					</p>
					
					
    			</div>
    		</div>
    		<div class="col-md-6 padding_0">
    			<div class="about_img"><img src="../../../assets/images/banner-bg.jpg"></div>
    		</div>
    	</div>
    </div>
    </div>

    <!-- section about end -->

    <!-- section choose start -->
   
      
    <!-- section choose end -->
    <!-- section testimonial start -->
    
    <!-- section testimonal end -->
    <!-- section contact start -->
    
    	
    <!-- section contact end -->
	<!--copyright section end -->
	<div class="copyright">
		<div class="container">
			<p class="copyright_text" style="font-family:Roboto">Copyright 2022 All Right Reserved By: Filament</p>
		</div>
	</div>

    <!--copyright section end -->
 




<router-outlet></router-outlet>
