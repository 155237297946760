import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUrl } from 'src/commons/common';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  showSuccess = false;
  foodRate = 0;
  serviceRate = 0;
  ambienceRate = 0;
  hygieneRate = 0;
  feedbackForm = new FormGroup({
    name: new FormControl(null, Validators.required ),
    phone: new FormControl(null, Validators.required),
    food: new FormControl(this.foodRate, Validators.required),
    service: new FormControl(this.serviceRate, Validators.required),
    ambience: new FormControl(this.ambienceRate, Validators.required),
    hygiene: new FormControl(this.hygieneRate, Validators.required),
    comments: new FormControl('', Validators.required)
  });

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onSubmit(){
    // console.log(this.feedbackForm.value);
    this.http.post(CommonUrl.URL+"feedback", this.feedbackForm.value)
    .subscribe(res => {
      console.log("res");
      this.feedbackForm.reset();
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
       }, 3000);
    })
  }

}
