<div class="menu">
  <div class="row">
    <div class="col-md-12 col-12 text-center">
      <img class="icon float-left"
      src="../../../../assets/images/logo_white.png"
      alt="Filament logo">
      <span class="header">Drinks</span>
      <img (click)="navigateHome()"
      class="icon float-right"
      src="../../../../assets/images/Group 63home.svg"
      alt="Filament logo">
      <img
      (click)="onBackClicked()"
      class="icon float-right"
      src="../../../../assets/images/Group 15back.svg"
      alt="Filament logo">
    </div>
  </div>
</div>
<div class="detail">
  <div class="row p-bo">
    <div class="col-md-12 col-12 text-center">
      <em>
        <img src="../../../../../assets/images/Group 30zigzag.svg" alt="zig">
      </em>&nbsp;
      <span class="title">{{subName}}</span>&nbsp;
      <em>
        <img src="../../../../../assets/images/Group 30zigzag.svg" alt="zig">
      </em>
    </div>
  </div>
  <div class="row p-t">
    <div class="col-md-12 col-12">
      <!-- <ul class="cat-list">
        <li
        class="sub-item"
        *ngFor="let cat of arr;">
          {{cat.name}}
          <em class="fa fa-chevron-right float-right"></em>
        </li>
      </ul> -->
      <div *ngFor="let cat of arr;">
        <div *ngIf="cat.children" class="m-b">
          <i *ngIf="cat.isCollapsed"
          class="fa fa-chevron-down"
          style="margin-left: 1rem;"
          (click)="cat.isCollapsed = !cat.isCollapsed">&nbsp;</i>
          <i *ngIf="!cat.isCollapsed"
          class="fa fa-chevron-up"
          style="margin-left: 1rem;"
          (click)="cat.isCollapsed = !cat.isCollapsed">&nbsp;</i>
          <span aria-controls="cat.dsc_id"
            [attr.aria-expanded]="!collapsed"
            (click)="cat.isCollapsed = !cat.isCollapsed">
            {{cat.name}}
          </span>
          <div id="cat.dsc_id"
              [ngbCollapse]="cat.isCollapsed">
              <table width="100%" style="margin-left: 2rem;">
                <tbody>
                  <tr *ngFor="let sub of cat.children">
                    <td width="70%">
                      {{sub.name}}
                    </td>
                    <td width="30%" class="price text-center">
                      <span>{{sub.price[0].price}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div *ngIf="!cat.children">
          <table width="100%" style="margin-left: 1rem;">
            <tbody>
              <tr>
                <td width="70%">
                  {{cat.name}}
                </td>
                <td width="30%" class="price text-center">
                  <span>{{cat.price[0].price}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container foot">
  <div class="footer">
    <p><b>Our Standard Measure is 30ml • We levy 10% Service Charge • Government Taxes as applicable</b></p>
  </div>
</div>

