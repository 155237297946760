<div class="menu">
  <div class="row text-center p-bo">
    <div class="col-md-4 col-4">
      <img src="../../../assets/images/Group 1logo.svg"
      alt="Filament" style="width: 80px;">
    </div>
    <div class="col-md-8 col-8 align-self-center">
      <h3
      style="color: #d4af37; font-weight: 700;">PLEASE RATE US</h3>
    </div>
  </div>

  <div class="row m-t">
    <div class="col-md-12 col-12">
      <div class="container">
        <form [formGroup] = "feedbackForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input type="text" class="form-control"
              name="name" formControlName="name"
              placeholder="Name*" required>
            <div class="alert alert-danger"
              *ngIf="feedbackForm.get('name').touched && feedbackForm.get('name').invalid">
              Please enter valid Name
            </div>
          </div>
          <div class="form-group">
            <input type="phone" class="form-control"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              name="phone" formControlName="phone"
              placeholder="Mobile No.*" required>
            <div class="alert alert-danger"
              *ngIf="feedbackForm.get('phone').touched && feedbackForm.get('phone').invalid">
              Please enter valid Phone Number
            </div>
          </div>
          <div class="row m-t">
            <div class="col-md-12 col-12">
              <label for="foodRating">How Was The Food?</label><br>
              <ngb-rating id="foodRating" [(rate)]="foodRate"
              [max]="5" formControlName="food">
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
              </ngb-rating>
            </div>
          </div>
          <div class="row m-t">
            <div class="col-md-12 col-12">
              <label for="foodRating">How Was The Service?</label><br>
              <ngb-rating id="foodRating" [(rate)]="serviceRate"
              [max]="5" formControlName="service">
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
              </ngb-rating>
            </div>
          </div>
          <div class="row m-t">
            <div class="col-md-12 col-12">
              <label for="foodRating">How Was The Ambience?</label><br>
              <ngb-rating id="foodRating" [(rate)]="ambienceRate"
              [max]="5" formControlName="ambience">
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
              </ngb-rating>
            </div>
          </div>
          <div class="row m-t">
            <div class="col-md-12 col-12">
              <label for="foodRating">How Was The Hygiene?</label><br>
              <ngb-rating id="foodRating" [(rate)]="hygieneRate"
              [max]="5" formControlName="hygiene">
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
              </ngb-rating>
            </div>
          </div>
          <div class="form-group m-t">
            <textarea class="form-control" placeholder="Comment"
            formControlName="comments"></textarea>
          </div>
          <div class="form-group">
            <button type="submit" [disabled]="!feedbackForm.valid"
            class="btn btn-block btn-cust">SUBMIT</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal" [style.display]="showSuccess? 'block': 'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Thank You For Your Feedback!
      </div>
      <div class="modal-body text-center">
        <em><img width="75px" height="75px"
          src="../../..//assets/images/logo_black.png" alt="Logo"></em>
        <p>See You Soon</p>
      </div>
    </div>
  </div>
</div>
