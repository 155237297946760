import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StreetSpecialsComponent } from './components/app/food/street-specials/street-specials.component';
import { IndianComponent } from './components/app/food/indian/indian.component';
import { PanAsianComponent } from './components/app/food/pan-asian/pan-asian.component';
import { EntryComponent } from './components/entry/entry.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { DrinksSubcategoryComponent } from './components/menu/drinks/drinks-subcategory/drinks-subcategory.component';
import { WakadDrinksSubcategoryComponent } from './components/wakad-menu/drinks/drinks-subcategory/drinks-subcategory.component';
import { DrinksComponent } from './components/menu/drinks/drinks.component';
import { WakadTabakhComponent  } from './components/wakad-menu/tabakh/tabakh.component';
import { WakadFoodComponent  } from './components/wakad-menu/food/food.component';
import { WakadDrinksComponent } from './components/wakad-menu/drinks/drinks.component';
import { FoodComponent } from './components/menu/food/food.component';
import { MainCourseComponent } from './components/menu/food/main-course/main-course.component';
import { MidCourseComponent } from './components/menu/food/mid-course/mid-course.component';
import { StartersComponent } from './components/menu/food/starters/starters.component';
import { SubcategoryDetailsComponent } from './components/menu/food/subcategory-details/subcategory-details.component';
import { MenuComponent } from './components/menu/menu.component';
import { HomeComponent } from './components/home/home.component';
import { WakadMenuComponent } from './components/wakad-menu/wakad-menu.component';
import { SundayBrunchComponent } from './sunday-brunch/sunday-brunch.component';


const routes: Routes = [
  // {path: '', redirectTo: 'menu', pathMatch: 'full'},
  {path: '', component: HomeComponent},
  {path: 'menu', component: MenuComponent},
  {path: 'wakad-menu', component: WakadMenuComponent},
  {path: 'urbo-menu', loadChildren: () => import('./components/urbo-menu/urbo-menu.module').then(m => m.UrboMenuModule)},
  // {path: 'menu/food', loadChildren:() => import('./components/menu/food/food.module').then(m => m.FoodModule)},
  {path: 'menu/food', component: FoodComponent, children: [
    { path: '', redirectTo: 'starters', pathMatch: 'full' },
   // { path: 'starters', component: StartersComponent},
    { path : 'street', component: StreetSpecialsComponent},
    { path: 'main-course', component: MainCourseComponent , children : [
      { path: '', redirectTo: 'mid-course', pathMatch: 'full' },
      { path: 'mid-course', component: MidCourseComponent},
      { path: 'pan-asian', component: PanAsianComponent},
      { path: 'indian', component: IndianComponent},
      { path: ':cat/:sub/:subId', component: SubcategoryDetailsComponent},

    ]},
    { path: ':cat/:sub/:subId', component: SubcategoryDetailsComponent},
  ]},
  {path: 'menu/drinks', component: DrinksComponent},
  {path: 'menu/drinks/:sub/:dc_id', component: DrinksSubcategoryComponent},
  {path: 'wakad-menu/drinks', component: WakadDrinksComponent},
  {path: 'wakad-menu/tabakh', component: WakadTabakhComponent},
  {path: 'wakad-menu/food', component: WakadFoodComponent},
  {path: 'wakad-menu/drinks/:sub/:dc_id', component: WakadDrinksSubcategoryComponent},
  // {path : '**', component: MenuComponent}
  {path: 'feedback', component: FeedbackComponent},
  {path: 'guestinfo', component: EntryComponent},
  {path: 'sundaybrunch', component: SundayBrunchComponent}
];

@NgModule({
  // tslint:disable-next-line:max-line-length
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
