import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss','./css/bootstrap.min.css','./css/style.css','./css/style2.css','./css/responsive.css','./css/owl.carousel.min.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToDrinks(){
    this.router.navigate(['/', 'menu', 'drinks']);
  }

  navigateToFood(){
    this.router.navigate(['/', 'menu', 'food']);
  }

}
